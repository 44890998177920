import React, {useState, useEffect} from "react";

export default function TrendGiletBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(lightContrast);
  const [darkenColorContrast, setDarkenColorContrast] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -50));
    setDarkenColorContrast(adjust(darkContrast, -100));
  }, [lightContrast, darkContrast]);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M371.186,428.218c-0.477-3.404-0.893-5.356-1.328-6.145c-1.058-1.889-1.225-10.535-1.225-10.618v-0.093
		c0.104-8.19,1.017-80.387-0.009-91.773c-0.914-10.183-0.271-56.765-0.053-70.808c0.042-2.49-0.778-4.941-2.294-6.912
		c-4.255-5.522-7.317-11.967-9.642-20.291c-2.086-7.484-1.972-15.631-1.846-23.509l0.009-1.131
		c0.208-14.209,1.681-28.336,3.104-40.283l0.083-0.695c1.442-12.215,2.947-24.837,6.6-36.908c0.851-2.844,1.536-6.373-0.706-8.729
		l0.074-0.105l-0.281-0.187c-5.823-3.882-16.451-10.793-22.253-13.554c-7.878-3.757-16.14-6.81-24.557-9.071
		c-6.674-1.807-13.462-3.373-20.156-4.693c-0.655-0.123-1.038-0.207-1.392-0.279c-0.383-0.083-0.664-0.145-1.203-0.24
		c0.186-1.193-0.51-2.988-1.361-5.024c-0.187-0.456-0.353-0.85-0.467-1.151l-7.961-21.683c-1.474-3.528-4.546-6.092-8.231-6.87
		c-4.608-0.965-10.234-1.183-15.194-1.37h-0.126c-1.38-0.052-2.729-0.104-4.006-0.166c-5.958-0.311-12.32-0.426-19.439-0.353
		c-8.253,0.083-19.556,0.196-28.44,1.494c-0.83,0.125-1.65,0.259-2.48,0.384l-1.038,0.167c-2.056,0.321-3.904,1.412-5.201,3.062
		L188.74,76.725l-0.249,0.592c-0.592,1.37-1.007,2.387-1.026,2.948c0,0.362,0.072,0.705,0.217,1.028
		c-4.846,1.161-11.333,2.865-17.468,4.598c-6.01,1.681-12.04,3.529-17.915,5.458l-1.194,0.394
		c-3.726,1.226-7.577,2.492-11.251,4.049c-3.612,1.535-7.358,3.476-11.479,5.946c-2.066,1.236-4.235,2.617-6.134,4.359
		c-1.722,1.568-3.726,3.706-3.955,6.312c-0.156,1.816,0.322,3.59,0.778,5.117c3.654,12.06,5.148,24.691,6.601,36.909l0.084,0.695
		c1.421,11.957,2.895,26.092,3.092,40.27l0.022,1.143c0.113,7.878,0.238,16.026-1.858,23.508c-2.316,8.314-5.388,14.76-9.643,20.292
		c-1.514,1.962-2.325,4.422-2.283,6.912c0.218,14.034,0.851,60.626-0.062,70.807c-1.028,11.407-0.114,83.596,0,91.846
		c-0.021,1.827-0.301,8.979-1.225,10.639c-0.436,0.789-0.862,2.741-1.339,6.145c-0.54,3.914,0.695,8.315,3.384,12.072
		c2.159,3.01,5.968,4.806,9.372,6.414c0.633,0.301,1.245,0.592,1.847,0.892c9.487,4.713,20.281,8.273,32.975,10.858
		c26.353,5.366,53.474,8.084,80.616,8.084c11.874,0,23.925-0.529,35.829-1.557c19.845-1.733,39.503-4.919,58.425-9.476
		c1.142-0.269,2.408-0.56,3.809-0.871C351.757,450.181,373.573,445.291,371.186,428.218z"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4671"
          strokeMiterlimit="10"
          strokeDasharray="1.3939,1.3929"
          d="
		M189.989,79.07c9.898-1.617,56.456-8.296,101.698,0.814"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4671"
          strokeMiterlimit="10"
          strokeDasharray="1.3939,1.3929"
          d="
		M123.582,108.096l2.958,6.428c0.519,1.674,1.126,3.293,1.601,4.857c3.738,12.347,5.193,24.97,6.707,37.72
		c0.383,3.226,0.749,6.456,1.089,9.688l0.757,7.758c0.677,7.617,1.147,15.252,1.256,22.902c0.087,6.099,0.243,12.233-0.554,18.297
		c-1.383,10.501-4.795,22.692-11.778,30.768c-2.349,2.717-5.205,5.519-7.678,8.131"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4671"
          strokeMiterlimit="10"
          strokeDasharray="1.3939,1.3929"
          d="
		M359.877,108.096l-2.957,6.428c-0.519,1.674-1.127,3.293-1.6,4.857c-3.739,12.347-5.194,24.97-6.708,37.72
		c-0.382,3.226-0.749,6.456-1.089,9.688l-0.756,7.758c-0.677,7.617-1.148,15.252-1.257,22.902
		c-0.086,6.099-0.243,12.233,0.555,18.297c1.382,10.501,4.794,22.692,11.777,30.768c2.349,2.717,5.205,5.519,7.678,8.131"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4671"
          strokeMiterlimit="10"
          strokeDasharray="1.3939,1.3929"
          d="
		M116.361,435.245c2.455,3.424,7.253,5.249,10.963,7.092c10.362,5.15,21.504,8.496,32.817,10.8
		c38.151,7.769,77.469,9.886,116.249,6.515c19.638-1.707,39.159-4.836,58.324-9.453c9.062-2.184,25.755-4.881,32.743-13.545"
        />
        <path
          fill="none"
          stroke={stitching}
          onClick={() => handleClick("stitching")}
          onMouseEnter={() => setStitchingActive(true)}
          onMouseLeave={() => setStitchingActive(false)}
          style={
            stitchingActive && !preview
              ? {stroke: adjust(stitching, 55)}
              : {stroke: stitching}
          }
          strokeWidth="0.4671"
          strokeMiterlimit="10"
          strokeDasharray="1.3939,1.3929"
          d="
		M116.361,416.354c2.455,3.424,7.253,5.249,10.963,7.092c10.362,5.15,21.504,8.497,32.817,10.8
		c38.151,7.769,77.469,9.886,116.249,6.516c19.638-1.707,39.159-4.837,58.324-9.454c9.062-2.183,25.755-4.881,32.743-13.545"
        />
        <path
          fill="#010101"
          d="M371.19,428.218c-0.477-3.404-0.893-5.356-1.329-6.145c-1.058-1.889-1.225-10.535-1.225-10.618v-0.093
		c0.104-8.19,1.017-80.387-0.009-91.773c-0.914-10.183-0.271-56.765-0.053-70.808c0.042-2.49-0.778-4.941-2.294-6.912
		c-4.255-5.522-7.317-11.967-9.642-20.291c-2.086-7.484-1.972-15.631-1.846-23.509l0.009-1.131
		c0.208-14.209,1.681-28.336,3.104-40.283l0.083-0.695c1.442-12.215,2.947-24.837,6.6-36.908c0.851-2.844,1.536-6.373-0.706-8.729
		l0.074-0.105l-0.281-0.187c-5.823-3.882-16.451-10.793-22.253-13.554c-7.878-3.757-16.14-6.81-24.557-9.071
		c-6.674-1.807-13.462-3.373-20.155-4.692c-0.655-0.123-1.038-0.207-1.392-0.279c-0.383-0.083-0.664-0.145-1.203-0.24
		c0.186-1.193-0.51-2.988-1.361-5.024c-0.187-0.456-0.353-0.85-0.467-1.151l-7.961-21.683c-1.474-3.528-4.546-6.092-8.231-6.87
		c-4.608-0.965-10.234-1.183-15.194-1.37h-0.126c-1.38-0.052-2.729-0.104-4.006-0.166c-5.958-0.311-12.32-0.426-19.439-0.353
		c-8.253,0.083-19.556,0.196-28.44,1.494c-0.83,0.125-1.65,0.259-2.48,0.384l-1.038,0.167c-2.056,0.321-3.904,1.412-5.201,3.062
		l-11.428,26.042l-0.249,0.592c-0.592,1.37-1.007,2.387-1.026,2.948c0,0.362,0.072,0.705,0.217,1.028
		c-4.846,1.161-11.333,2.865-17.468,4.598c-6.01,1.681-12.04,3.529-17.915,5.458l-1.194,0.394
		c-3.726,1.226-7.577,2.492-11.251,4.049c-3.612,1.535-7.358,3.476-11.479,5.946c-2.065,1.236-4.235,2.617-6.134,4.359
		c-1.722,1.568-3.726,3.706-3.954,6.312c-0.156,1.816,0.322,3.59,0.778,5.117c3.653,12.06,5.148,24.691,6.601,36.909l0.084,0.695
		c1.421,11.957,2.895,26.092,3.092,40.27l0.022,1.143c0.113,7.878,0.238,16.026-1.858,23.508c-2.316,8.314-5.388,14.76-9.643,20.292
		c-1.514,1.962-2.325,4.422-2.283,6.912c0.218,14.034,0.851,60.626-0.062,70.807c-1.028,11.407-0.114,83.596,0,91.846
		c-0.021,1.827-0.301,8.979-1.225,10.639c-0.436,0.789-0.861,2.741-1.339,6.145c-0.54,3.914,0.695,8.315,3.384,12.072
		c2.159,3.01,5.968,4.806,9.372,6.414c0.633,0.301,1.246,0.592,1.848,0.892c9.487,4.713,20.281,8.273,32.975,10.858
		c26.353,5.366,53.474,8.084,80.616,8.084c11.874,0,23.925-0.529,35.829-1.557c19.845-1.733,39.503-4.919,58.425-9.476
		c1.142-0.269,2.408-0.56,3.809-0.871C351.761,450.181,373.577,445.291,371.19,428.218 M360.863,108.973
		c-0.893-0.582-1.836-1.204-2.834-1.848C359.027,107.759,359.97,108.382,360.863,108.973 M293.263,82.599
		c0.032-0.041,0.052-0.074,0.074-0.115l-0.094,0.25h-0.01l-0.104-0.022C293.18,82.682,293.221,82.641,293.263,82.599 M189.73,77.846
		l11.552-26.394c1.079-1.339,2.605-2.232,4.297-2.491l0.851-0.135c0.882-0.145,1.764-0.281,2.657-0.416
		c8.802-1.297,20.042-1.4,28.252-1.483c7.006-0.074,13.337,0.042,19.357,0.343c1.329,0.073,2.719,0.125,4.162,0.176
		c4.899,0.186,10.463,0.394,14.957,1.339c3.25,0.685,5.968,2.948,7.255,6.051l7.951,21.64c0.114,0.312,0.291,0.737,0.488,1.214
		c0.415,0.987,1.65,3.966,1.235,4.475c0,0-0.093,0.052-0.404,0c-17.542-3.539-35.322-4.692-51.088-4.692
		c-25.241,0-45.305,2.959-50.858,3.872c-0.477,0.072-0.934-0.031-1.235-0.291c-0.228-0.187-0.343-0.468-0.332-0.769
		C188.827,79.953,189.46,78.479,189.73,77.846 M188.712,81.561c0.062,0.052,0.114,0.093,0.176,0.135l-0.093,0.021l-0.259-0.332
		C188.598,81.446,188.65,81.51,188.712,81.561 M338.423,451.79c-1.38,0.311-2.667,0.602-3.84,0.882
		c-18.849,4.536-38.433,7.712-58.217,9.434c-39.109,3.405-78.156,1.214-116.061-6.496c-12.58-2.564-23.26-6.083-32.653-10.754
		c-0.612-0.3-1.256-0.602-1.91-0.913c-3.218-1.525-6.85-3.248-8.802-5.968c-2.501-3.476-3.642-7.525-3.145-11.094
		c0.581-4.131,0.986-5.325,1.183-5.677c1.183-2.138,1.37-10.348,1.392-11.294c-0.011-0.788-1.06-79.94-0.011-91.72
		c0.924-10.214,0.291-56.889,0.073-70.953c-0.042-2.19,0.675-4.35,2.003-6.073c4.37-5.667,7.504-12.257,9.881-20.747
		c2.138-7.681,2.014-15.923,1.899-23.893l-0.021-1.142c-0.197-14.252-1.681-28.43-3.103-40.417l-0.082-0.695
		c-1.454-12.268-2.959-24.952-6.644-37.138c-0.426-1.4-0.872-3.02-0.727-4.607c0.187-2.139,1.972-4.027,3.508-5.429
		c1.816-1.66,3.923-2.999,5.927-4.203c4.069-2.439,7.764-4.35,11.303-5.854c3.633-1.546,7.463-2.802,11.158-4.017l1.194-0.394
		c5.844-1.921,11.853-3.757,17.852-5.439c6.529-1.837,13.327-3.623,18.216-4.774c0.54,0.27,1.183,0.363,1.828,0.259
		c8.977-1.463,56.088-8.324,101.446,0.809c0.457,0.093,0.82,0.074,1.152-0.061c0.996,0.155,1.318,0.228,1.848,0.343
		c0.311,0.061,0.706,0.145,1.381,0.279c6.662,1.309,13.419,2.876,20.072,4.661c8.324,2.242,16.514,5.262,24.319,8.988
		c5.615,2.678,15.953,9.383,21.672,13.182l0.042,0.041c2.19,1.805,1.547,5.085,0.737,7.743c-3.685,12.185-5.19,24.879-6.642,37.147
		l-0.084,0.695c-1.422,11.978-2.906,26.144-3.114,40.416l-0.01,1.143c-0.124,7.971-0.239,16.211,1.899,23.883
		c2.377,8.489,5.511,15.081,9.872,20.748c1.339,1.733,2.044,3.892,2.013,6.072c-0.218,14.063-0.851,60.738,0.063,70.953
		c1.068,11.853,0.009,90.943,0,91.752c0,0.364,0.165,9.062,1.39,11.273c0.197,0.343,0.602,1.547,1.173,5.667
		C372.072,444.253,351.013,448.966,338.423,451.79"
        />
      </g>
    </svg>
  );
}
